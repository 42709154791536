import { Tooltip, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MultilineText } from 'modules/picasso-ui/present/MultilineText';
import { orElse } from 'utils/objectUtils';

export const listItemWarningColor = '#ffdb57';

const stylesDef = {
  strike: {
    '&>*': {
      opacity: '0.4',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '0',
      height: '1px',
      width: '100%',
      backgroundColor: '#d9d9d9',
    }
  },
  flag: {
    position: 'absolute',
    top: '0px',
    left: '-1px',
    width: '5px',
    height: '100%',
    background: listItemWarningColor
  },
}

export default function ListItemStyled({ idx, component, children, flags, flagsRenderer, className, showDividers, showBorders, hoverHighlight, hoverHighlightOverflow, showConnector, variant, withPadding, onClick, startIcon, strike, ...props }) {
  //console.debug('Render ListItemStyled')
  const classes = useStyles()

  const styles = []

  if (strike) {
    styles.push(stylesDef.strike)
  }
  if (props.sx) {
    styles.push(props.sx)
  }

  showDividers = orElse(showDividers, true)
  hoverHighlight = orElse(hoverHighlight, true)

  variant = orElse(variant, 'withDividers')

  if (variant === 'card') {
    showDividers = false
  } 
  else if (variant === 'smoothItem') {
    hoverHighlight = false
  }

  const classNames = [classes.listItem];

  if (className) {
    classNames.push(className)
  }

  if (onClick) {
    classNames.push(classes.listItemClickable)
  }

  if (showDividers) {
    classNames.push(classes.listItemWithDividers)
  }

  if (showBorders) {
    classNames.push(classes.listItemWithBorders)
  }

  if (hoverHighlight) {
    classNames.push(classes.listItemWithHoverHighlight)
  }

  if (hoverHighlightOverflow) {
    classNames.push(classes.listItemWithHoverHighlightOverflow)
  }

  if (withPadding) {
    classNames.push(classes.listItemWithPadding)
  } else if (withPadding === false) {
    classNames.push(classes.listItemWithoutPadding)
  }

  if (variant === 'card') {
    classNames.push(classes.listItemCard)
  }

  if (variant === 'smoothItem') {
    classNames.push(classes.listItemSmoothItem)
    if (onClick) {
      classNames.push(classes.listItemSmoothItemClickable)
    }
  
  }

  const firstConnectorVerticalLineStyle = {
    height: '15px',
  }

  return (
      <ListItem className={classNames.join(' ')} onClick={onClick} {...props} sx={styles} component={component}>
        {flagsRenderer ? flagsRenderer(flags) : <ListItemFlags flags={flags} />}
        {showConnector && <ListItemConnector verticalLineStyle={idx === 0 ? firstConnectorVerticalLineStyle : null}/>}
        {startIcon}
        {children}
      </ListItem>
  )
}

const isObject = (value) => {
  return value && typeof value === 'object' && !Array.isArray(value);
};

export const ListItemFlags = ({flags}) => {

  if (!flags) {
    return null;
  }

  let background;

  let flagsText = ''
  for (const flag of flags) {
    if (isObject(flag)) {
      flagsText = flag.title

      if (flag.background) {
        background = flag.background
      }
    } else {
      flagsText += flag
    }
  }

  return (
    <Tooltip title={<MultilineText>{flagsText}</MultilineText>} placement="left">
      <div css={[stylesDef.flag, {background}]} className="li-flag"></div>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    position: 'relative',
    padding: 0,
    '&:hover .visibleOnHover': {
      visibility: 'visible',
    },
    '&:hover .visibleOnHoverResponsive': {
      visibility: 'visible',
    },
    '& .visibleOnHoverResponsive': {
      [theme.breakpoints.down('md')]: {
        visibility: 'visible',
      },
    },
    '&:hover': { 
      background: 'none',
      '& .li-flag': {
        width: '12px',
      }
     },
  },
  listItemClickable: {
    cursor: 'pointer',
  },
  listItemWithDividers: {
    '&+$listItem': {
      borderTop: '1px solid ' + theme.palette.divider,
    }
  },
  listItemWithPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listItemWithoutPadding: {
    padding: '0 !important',
  },
  listItemWithBorders: {
    borderLeft: '1px solid ' + theme.palette.divider,
    borderRight: '1px solid ' + theme.palette.divider,
  },
  listItemWithHoverHighlight: {
    '&:hover': { 
      backgroundColor: theme.palette.action.hover,
      borderRadius: '5px',
     },
  },
  listItemWithHoverHighlightOverflow: {
    '&:hover': { 
      //outline: `5px solid ${theme.palette.action.hover}`,
      boxShadow: `-5px 0px 0px 0px ${theme.palette.action.hover}, 5px 0px 0px 0px ${theme.palette.action.hover}`,
      backgroundColor: theme.palette.action.hover,
      borderRadius: '5px',
     },
  },
  listItemCard: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '12px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '&+$listItemCard': {
      marginTop: theme.spacing(1),
    },
  },
  listItemSmoothItem: {
    //boxShadow: 'rgba(0,0,0,0.08) 0px 3px 11px',
    padding:  theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderRadius: '10px',
    '&+$listItemSmoothItem': {
      marginTop: theme.spacing(2),
    },
    boxShadow: 'rgb(0 0 0 / 2%) 0px 3px 4px',
    border: '1px solid #f2f2f2',
  },
  listItemSmoothItemClickable: {
    '&:hover': {
      //boxShadow: 'rgb(0 0 0 / 10%) 0px 5px 15px',
      //backgroundColor: lighten(theme.palette.action.hover, 0.2),
      //boxShadow: 'none',
      //border: `1px solid ${theme.palette.divider}`,
      boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 5px',
    },
  },
  //---
    itemConnector: {
      width: '20px',
      height: '20px',
      position: 'relative',
      marginRight: theme.spacing(1),
      '& $verticalLine, & $horizontalLine': {
          backgroundColor: '#eaeaee',
      },
  },
  verticalLine: {
      width: '1px',
      height: '60px',
      position: 'absolute',
      left: '0px',
      top: '50%',
      transform: 'translateY(-100%)',
  },
  horizontalLine: {
      height: '1px',
      width: '100%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
  },
}));


export const ListItemConnector = ({verticalLineStyle}) => {

  const classes = useStyles()

  return (
      <div className={classes.itemConnector}>
          <div className={classes.verticalLine} style={verticalLineStyle}/>
          <div className={classes.horizontalLine} />
      </div>
  )

}