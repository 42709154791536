import { ClassNames } from '@emotion/react';
import { alpha, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFunction, orElse } from 'utils/objectUtils';
import { isString, replaceUrlsWithHtmlAnchor, replaceUrlsWithHtmlAnchorAndText } from 'utils/stringUtils';

const renderAfter = {
    underline: {
        content: '<svg class="handwritten-stroke" viewBox="0 0 154 13"><use href="#root"></use></svg>',
        strokeSvgRequired: true
    },
}

//new definition
const tagDefs = {
    markerUnderline: {
        css: theme=>({
            position: 'relative',
            display: 'inline-block',
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '0px',
                bottom: '0.07em',
                width: 'calc(100% + 10px)',
                height: '14px',
                transform: 'skew(-12deg) translateX(-5px)',
                background: theme.palette.textmarker || alpha(theme.palette.primary.main, 0.8),
                zIndex: 1,
            },
            // extra span rendered due to wraptext: true
            '& > span': {
                zIndex: 2,
                position: 'relative',
            },
            '&[primary]::before': {
                background: alpha(theme.palette.primary.main, 0.8),
            },
            '&[secondary]::before': {
                background: theme.palette.textmarker || alpha(theme.palette.secondary.main, 0.8),
            },
            '&[green]::before': {
                background: 'rgb(113, 248, 207)',
            },
            '&[middlethick]::before': {
                height: '8px',
            },
            '&[thin]::before': {
                height: '4px',
            },
            '&[thindyn]::before': {
                height: '0.2em',
            },
            '&[overflowless]::before': {
                width: '100%',
                transform: 'skew(-12deg)',
            },
            [theme.breakpoints.up('sm')]: {
                '&:not([wrap])': {
                    whiteSpace: 'nowrap',
                },
            },
        }),
        wraptext: true
    },
    markUnderlineOrange: {
        css: ()=>({
            position: 'relative',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            zIndex: 2,
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '0px',
                bottom: '6px',
                width: 'calc(100% + 20px)',
                height: '14px',
                transform: 'skew(-12deg) translateX(-10px)',
                background: 'rgb(255 171 41)', //'rgba(238,111,87,0.5)',
                zIndex: '-1',
            },
        })
    },
    checkmark: {
        css: {
            '&:before': {
                content:  '"\u2713"',
              },
             marginRight: '6px',
        },
    },
    highl: {
        css: {
            fontWeight: 700,
        }
    },
    primaryGradient: {
        css: {
            backgroundImage: `linear-gradient(135deg, #1fb1d2 0%, var(--palette-primary-main) 100%)`,
            color: 'transparent',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
        }
    },
    gradient: {
        css: (theme, colorPrimary, colorGradient) =>({
            background: colorGradient,
            color: 'transparent',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
        })
    },
    markerHighlight: {
        css: (theme, colorPrimary)=>({
            backgroundColor: '#deeff7',
            // Marker-StylehHighlighted text looks bad when it breaks line.
            [theme.breakpoints.up('sm')]: {
                '&:not([wrap])': {
                    whiteSpace: 'nowrap',
                },
            },
            '&[wrap]': {
                whiteSpace: 'wrap',
            },
            '&[primary]': {
                backgroundColor: colorPrimary||theme.palette.primary.main,
            },
            '&[withpadding]': {
                padding: '6px 0.5em',
                boxDecorationBreak: 'clone',
            },
            '&[paddingsmall]': {
                padding: '0.5em 0',
                boxDecorationBreak: 'clone',
            }
        }),
    },
    primary: {
        css: (theme, colorPrimary)=>({
            color: colorPrimary||theme.palette.primary.main,
        })
    },
}

const useStyles = makeStyles((theme)=>({
    '@global': {
        '.handwritten-stroke': {
            display: 'block',
            width: 'calc(100% + 10px)',
            height: '20px',
            stroke: '#407cc9',
            position: 'absolute',
            left: '-5px',
            bottom: '-10px',
            'stroke-width': '4',
        },
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
    green: {
        color: '#85c685'
    },
    pink: {
        color: '#c67cd2'
    },
    blue: {
        color: '#38a8e0'
    },
    yellow: {
        color: '#ecbe4f'
    },
    underline: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
    },
    i: {
        fontStyle: 'italic',
    },
    b: {
        fontWeight: 600,
        color: '#3e4960',
    },
    b500: {
        fontWeight: 500,
    },
    b600: {
        fontWeight: 600,
    },
    b700: {
        fontWeight: 700,
    },
})) 

const strokeSvg = 
    <svg id="handwritten-stroke" xmlns="http://www.w3.org/2000/svg" width="0" height="0">
    <defs>
        <path id="root" fill="none" d="M2 2c50.7 2.6 100 3.2 150 1.7-46.5 2-94 4.4-139.2 7.3 45.2-1.5 91.6-1.7 136.5-.6" strokeLinecap="round" strokeLinejoin="round" vectorEffect="non-scaling-stroke"/>
    </defs>
    </svg>

/**
 * 
 * Can interpret a few custom tags and multiline
 * 
 */
export const FormattedText = ({children, enableLinks, linkText, style, renderTextElem, colorPrimary, colorGradient}) => {

    const classes = useStyles()

    // Important: must sort tagdefs by key length, so that longest tag is matched first.
    const allowedTags = [...Object.keys(classes), ...Object.keys(tagDefs).sort((a, b) => b.length - a.length)] //merge old and new

    const getEffectiveTextElem = (text) => {

        text = sanitizeHtmlTags(text, allowedTags)

        let strokeSvgRequired = false
        let updated = text + '';
        allowedTags.forEach(tag=>{
            let result = replaceTag(updated, tag)
            if (result.strokeSvgRequired === true) {
                strokeSvgRequired = true
            }
            updated = result.updated
        })

        updated = updated.replace(/\\n/g, '<br/>')

        if (enableLinks) {
            if (linkText) {
                updated = replaceUrlsWithHtmlAnchorAndText(updated, linkText)
            } else {
                updated = replaceUrlsWithHtmlAnchor(updated)
            }
        }

        return {
            updated,
            strokeSvgRequired
        }
    }

    const replaceTag = (text, tag) => {
        let updated = text + '';
        let hasTag = text.includes('<'+tag+'>')
        
        const afterContent = renderAfter[tag] ? renderAfter[tag] : null
        const afterContentStr = afterContent?.content ? afterContent?.content : ''

        const tagDef = tagDefs[tag]
        if (tagDef) {
            updated = updated.replace(new RegExp('<'+tag+'((\\s+[a-z]*)*?)>', 'g'), `<span class="emotion:${tag}"$1>${tagDef.wraptext?'<span>':''}`)
        } else {
            updated = updated.replace(new RegExp('<'+tag+'>', 'g'), '<span class="'+classes[tag]+'">')
        }

        updated = updated.replace(new RegExp('</'+tag+'>', 'g'), `${afterContentStr}</span>${tagDef?.wraptext?'</span>':''}`)
        return {
            updated,
            strokeSvgRequired: hasTag && afterContent ? afterContent.strokeSvgRequired : false,
        }
    }
    
    if (!isString(children)) {
        return children
    }

    const result = getEffectiveTextElem(children, allowedTags)

    const renderDefaultTextElem = (props) => {
        return <span {...props}></span>
    }

    renderTextElem = renderTextElem || renderDefaultTextElem

    const renderWithEmotionClassNames = (html, css, theme) => {

        // Important: must sort tagdefs by key length, so that longest tag is matched first.
        Object.keys(tagDefs).sort((a, b) => b.length - a.length).forEach(tag=>{
            const searchKey = 'emotion:'+tag
            if (html.includes(searchKey)) {
                const className = css(isFunction(tagDefs[tag].css) ? tagDefs[tag].css(theme, colorPrimary, colorGradient) : tagDefs[tag].css)
                html = html.replace(new RegExp(searchKey, 'g'), className)
            }
        })
        
        return renderTextElem({
            css:[
                theme=>({
                    [theme.breakpoints.up('sm')]: {
                        // Line-breaks included in the content look sometimes weird on very small screens because there are to many line break.
                        // There only from larger screens on.
                        whiteSpace: 'pre-line'
                    }
                }), 
                style
            ],
            dangerouslySetInnerHTML: { __html: html }
        })
    }

    return (
        children ? 
        <>  
            {result.strokeSvgRequired === true && 
                <>
                    {strokeSvg}
                </>
            }
            <ClassNames>
                {({ css, theme }) => (
                    renderWithEmotionClassNames(result.updated, css, theme)
                )}
            </ClassNames>
        </>
        : <></>
    )

}

export const FormattedTypography =  ({enableLinks, linkText, children, colorGradient, ...props}) => {
   
    const renderTextElem = (p) => <Typography {...p} {...props}/>

    return <FormattedText renderTextElem={renderTextElem} enableLinks={enableLinks} linkText={linkText} colorGradient={colorGradient}>{children}</FormattedText>
}

export const Dot = ({size, lineWidth, ml, color}) => {

    size = size || '10px'
    lineWidth = lineWidth || '120px'
    ml = orElse(ml, '0')

    const styles = {
        root: theme=>({
            position: 'relative',
            display: 'inline-block',
            width: size,
            height: size,
            backgroundColor: color||theme.palette.primary.main,
            borderRadius: '100%',
            marginLeft: ml,
            '::before': {
                content: '""',
                position: 'absolute',
                backgroundImage: 'linear-gradient(90deg,transparent,'+alpha(color||theme.palette.primary.main, 0.6)+')',
                width: lineWidth,
                height: size,
                right: 'calc(100% - '+size+'/ 2)',
                zIndex: '-1'
            },
        })
    }

    return <span css={styles.root}></span>

}

/**
 * 
 * Removes tags that are not on the allowList.
 * Removes tags with content and also tags without a closing counterpart (e.g., <img>).
 * 
 * @param {*} inputString 
 * @param {*} allowList 
 * @returns 
 */
function sanitizeHtmlTags(inputString, allowList) {
    // Create a regular expression pattern for tags
    const tagPattern = /<\/?([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/gi;

    function recursiveFilter(str) {
        let result = str;
        let matches;

        // Collect all tags from the current string
        const allTags = [];
        while ((matches = tagPattern.exec(str)) !== null) {
            allTags.push({ tag: matches[1], fullMatch: matches[0] });
        }

        // If a tag isn't allowed, remove it, and run the function again on its inner content
        for (let tagData of allTags) {
            const tag = tagData.tag;

            if (!allowList.includes(tag)) {
                const openTagPattern = new RegExp(`<${tag}[^>]*>`, 'i');
                const closeTagPattern = new RegExp(`</${tag}>`, 'i');
                const contentBetweenTagsPattern = new RegExp(`<${tag}[^>]*>((.|\n)*?)</${tag}>`, 'i');
                
                const contentMatch = contentBetweenTagsPattern.exec(result);
                if (contentMatch) {
                    result = result.replace(contentBetweenTagsPattern, recursiveFilter(contentMatch[1]));
                    result = result.replace(openTagPattern, '').replace(closeTagPattern, '');
                } else {
                    // If no closing tag is found, remove the opening tag directly
                    result = result.replace(tagData.fullMatch, '');
                }
            }
        }

        return result;
    }

    return recursiveFilter(inputString);
}